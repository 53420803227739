<template>
  <div class="main-content-box">
    <template v-for="(item,key1) in valueArr">
      <div class="main-content" :key="key1">
        <div class="main-title">区域-{{key1}}</div>
        <div class="main-btn">
          <span class="btn-add" @click="addLink(key1)">追加项</span>
        </div>
        <div class="from">
          <div class="row">
            <div class="title">区域-{{key1}} 标题</div>
            <input type="text" placeholder="请输入标题" v-model.trim="item.group">
          </div>
          <div class="row">
            <template v-for="(line,key2) in item.line">
              <div class="row-box" :key="key2">
                <div class="title name">联系人</div>
                <input type="text" placeholder="请输入联系人" v-model.trim="line.name">
                <div class="title job">联系方式</div>
                <input type="text" placeholder="请输入联系方式" v-model.trim="line.links">
                <img class="del" @click="del(key1,key2)" src="@/assets/user_works_del_icon.png">
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <div class="btn-box">
      <span class="btn-confirm" @click="edit">保存</span>
    </div>
  </div>
</template>

<script>
import {getConfigInfo,editConfig} from "@/request/api";
import {Warning} from "@/plugins/warning";

export default {
  name: "page_link",
  components:{

  },
  data() {
    return {
      configObj:{},
      valueArr:{}
    }
  },
  computed: {

  },
  created() {
    this.getInfo()
  },
  mounted() {

  },
  methods: {
    addLink(val){
      this.valueArr[val].line.push(
          {"name":"","links":""}
      )
    },
    del(key1,key2){
      this.valueArr[key1].line.splice(key2,1)
    },
    getInfo(){
      const requestData = {"key":"link_us"};
      getConfigInfo(requestData).then((res)=>{
        this.configObj = res.data;
        this.valueArr = JSON.parse(res.data.value);
      })
    },
    edit(){
      let val = JSON.stringify(this.valueArr);

      const requestData = {
        "key":this.configObj.key,
        "value": val,
      };

      editConfig(requestData).then(()=>{
        Warning.success("操作成功");
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .main-content{
    width: 100%;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-bottom: 45px;
    .main-title{
      font-size: 24px;
      font-weight: 600;
      color: #141414;
      margin-left: 30px;
      margin-top: 30px;
    }
    .main-btn{
      font-size: 24px;
      font-weight: 600;
      color: #141414;
      margin-right: 30px;
      margin-top: 30px;
      .btn-add{
        display: block;
        width: 100px;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
    .from{
      width: 100%;
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 15px;
      border-collapse:separate;
      border-spacing:0px 30px;
      position: relative;
      .row{
        width: 100%;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        .title{
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }
        & > input{
          height: 50px;
          min-width: 500px;
          border-radius: 25px 25px 25px 25px;
          opacity: 1;
          border: 2px solid #DDD1B4;
          padding-left: 15px;
          padding-right: 15px;
        }
        & > textarea{
          height: 200px;
          min-width: 500px;
          border-radius: 25px 25px 25px 25px;
          opacity: 1;
          border: 2px solid #DDD1B4;
          padding: 15px;
        }
        .el-select{
          margin-right: 15px;
        }
        .address{
          margin-top: 15px;
          min-width: 600px;
        }
      }
      .thumb-box{
        width: 300px;
        height: 200px;
        position: absolute;
        top:0;
        right: 30px;
        label {
          display: block;
          width: 100%;
          height: 100%;
          & > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            margin-top: 15px;
            border: 0.5px solid $blockColor--1;
          }
        }
      }
      .row-box{
        width: 33%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        float: left;
        position: relative;
        padding: 15px;
        border: 1px solid #DDD1B4;
        .title{
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }
        .name{
          width: 100%;
        }
        .job{
          width: 100%;
        }
        .del{
          width: 18px;
          height: 23px;
          position: absolute;
          top: 25px;
          right: 35px;
          cursor: pointer;
        }
        & > input{
          height: 50px;
          width: 100%;
          border-radius: 25px 25px 25px 25px;
          opacity: 1;
          border: 2px solid #DDD1B4;
          padding-left: 15px;
          padding-right: 15px;
          margin-right: 30px;
        }
        & > textarea{
          height: 200px;
          width: 100%;
          border-radius: 25px 25px 25px 25px;
          opacity: 1;
          border: 2px solid #DDD1B4;
          padding: 15px;
        }
        .el-select{
          margin-right: 15px;
        }
        .address{
          margin-top: 15px;
          min-width: 600px;
        }
      }
      .btn-box{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 40px;
        padding-left: 15px;
        padding-right: 15px;
        .btn-confirm{
          display: block;
          width: 100%;
          line-height: 48px;
          font-size: 21px;
          font-weight: 300;
          text-align: center;
          cursor: pointer;
          color: #fff;
          border-radius: 25px;
          background-color: #ba0704;
        }
      }
    }
  }
  .btn-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    .btn-confirm{
      display: block;
      width: 100%;
      line-height: 48px;
      font-size: 21px;
      font-weight: 300;
      text-align: center;
      cursor: pointer;
      color: #fff;
      border-radius: 25px;
      background-color: #ba0704;
    }
  }
}
</style>
